import React, { useState } from 'react';
import {
    TextField, FormControl, Grid, InputLabel, Select, MenuItem
} from '@mui/material';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

function DosageTimingInputs({ timing, modifyDosage }) {
    let wordSelections = [
        { title: 'Hommik e/s (07:30)', value: 'Hommik e/s' },
        { title: 'Hommik p/s (08:30)', value: 'Hommik p/s' },
        { title: 'Lõuna (12:00)', value: 'Lõuna' },
        { title: 'Õhtu (17:00)', value: 'Õhtu' },
        { title: 'Ööseks (22:00)', value: 'Ööseks' }
    ];
    let timeSelections = [
        { title: '07:30', value: '07:30' },
        { title: '08:30', value: '08:30' },
        { title: '12:00', value: '12:00' },
        { title: '17:00', value: '17:00' },
        { title: '22:00', value: '22:00' }
    ];

    let chooseActiveInput = (value) => {
        if (wordSelections.map(x => x.value).includes(value))
            return 'word';
        if (timeSelections.map(x => x.value).includes(value))
            return 'time';

        return 'custom';
    };

    const [timingValue, setTimingValue] = useState(timing);
    const [activeInput, setActiveInput] = useState(chooseActiveInput(timing));

    let proxyModify = (name, value) => {
        setTimingValue(value);
        setActiveInput(chooseActiveInput(value));
        modifyDosage(name, value);
    };

    return (
        <Grid item xs={12} container direction='row' spacing={1} alignItems='center'>
            <Grid item xs={4}>
                <FormControl fullWidth
                    margin='normal'>
                    <InputLabel>Aeg (tekst)</InputLabel>
                    <Select
                        value={activeInput === 'word' ? timingValue : ''}
                        label="Aeg (tekst)"
                        onChange={e => proxyModify('timing', e.target.value)}
                    >
                        {wordSelections.map((value) => <MenuItem key={value.value} value={value.value}>{value.title}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth
                    margin='normal'>
                    <InputLabel>Aeg (kell)</InputLabel>
                    <Select
                        value={activeInput === 'time' ? timingValue : ''}
                        label="Aeg (kell)"
                        onChange={e => proxyModify('timing', e.target.value)}
                    >
                        {timeSelections.map((value) => <MenuItem key={value.value} value={value.value}>{value.title}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <DesktopTimePicker
                    mask='__:__'
                    inputFormat='HH:mm'
                    ampm={false}
                    views={['hours']}
                    label="Muu kellaaeg"
                    value={activeInput === 'custom' ? DateTime.fromISO(timingValue).toISO() : null}
                    onChange={(dateValue, stringValue) => {
                        if (dateValue === null)
                            proxyModify('timing', '');
                        else if (stringValue === undefined)
                            proxyModify('timing', dateValue.toFormat('HH:mm'))
                        else
                            proxyModify('timing', stringValue);
                    }}
                    renderInput={(params) => <TextField
                        margin='normal'
                        fullWidth
                        {...params}
                        value={activeInput === 'custom' ? params.value : null}
                    />}
                />
            </Grid>
        </Grid>
    );
}
export default DosageTimingInputs;