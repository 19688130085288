import axios from 'axios';
import { verifyUser } from './App';

const customAxios = (token, setUserContext) => {
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(function (config) {
        if (token)
            config.headers.Authorization = "Bearer " + token;
        return config;
    });

    axiosInstance.interceptors.response.use(undefined, (error) => {
        if (error.response.status === 401) {
            return verifyUser(setUserContext).then((token) => {
                error.config.headers.Authorization = "Bearer " + token;
                return axios.request(error.config);
            });
        }
        return Promise.reject(error);
    });

    return axiosInstance;
};

export default customAxios;