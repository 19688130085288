import React, { useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchInput({ event }) {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearch = useMemo(() => debounce(query => event(query), 400), [event]);

    const handleInputChange = event => {
        let query = event.target.value;
        setSearchTerm(query);
        debouncedSearch(query);
    };

    return (
        <TextField
            name='search'
            value={searchTerm}
            label='Otsi'
            onChange={handleInputChange}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default SearchInput;
