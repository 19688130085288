import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

export default function Footer() {

    return (
        <Paper elevation={4} id="footer">
            <Grid container direction="row" style={{"height": "100%"}} alignContent="center">
                <Grid item xs={1} />
                <Grid item xs={10} textAlign="center"><Typography variant="subtitle2">Copyright &copy; SaarIS 2022</Typography></Grid>
            </Grid>
        </Paper>
    );
}
