import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
    Grid, Button, TextField, Typography, Autocomplete,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox
} from '@mui/material';
import { UserContext } from '../context/UserContext';
import axios from '../CustomAxios';
import printJS from 'print-js';
import { DateTime } from 'luxon';
import downloadAsFile from '../helpers/downloadAsFile';


function TreatmentReport() {
    const [userContext, setUserContext] = useContext(UserContext);
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [reportPatients, setReportPatients] = useState(undefined);
    const [selectedPatients, setSelectedPatients] = useState([]);

    const getAllInstitutions = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'institutions')
            .then(res => {
                let sortedInstitutions = res.data.rows;
                sortedInstitutions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                setInstitutions(sortedInstitutions);
            });
    }, [userContext.token, setUserContext]);

    const getTreatmentPatients = () => {
        return axios(userContext.token, setUserContext).get(`${process.env.REACT_APP_API_ENDPOINT}reports/treatment/patients/${selectedInstitution}`)
            .then(res => {
                let surnameToFront = (name) => {
                    let nameArr = name !== null ? name.split(' ').filter(x => x !== '') : [];
                    nameArr.splice(0, 0, nameArr.pop());
                    return nameArr.join(' ');
                }
                res.data.sort((a, b) => surnameToFront(a.name).localeCompare(surnameToFront(b.name), 'et'));
                setReportPatients(res.data);
                setSelectedPatients(res.data.map(x => x.id));
            });
    };

    const handlePrint = async () => {
        let patients = reportPatients.filter(x => selectedPatients.indexOf(x.id) > -1).map(x => {
            return {
                nimi: x.name,
                isikukood: x.identificationCode,
                asutus: x.institution.name,
                algus: x.treatments[0]?.startDate !== undefined ? DateTime.fromISO(x.treatments[0]?.startDate).toFormat('dd.MM.yyyy') : ''
            };
        });

        var printConfig = {
            printable: patients,
            properties: ['nimi', 'isikukood', 'asutus', 'algus'],
            type: 'json',
            documentTitle: 'patsientide-raport',
            header: `<h3>Patsientide kokkuvõte (kokku ${patients.length})</h3>`,
        };
        return printJS(printConfig);
    };

    const handleExcel = async () => {
        return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'reports/treatment/excel',
            {
                patientIds: selectedPatients
            },
            {
                responseType: 'blob'
            }
        ).then(res => {
            downloadAsFile(`saaris-raport-${institutions.find(x => x.id === selectedInstitution).name}-${DateTime.now().toFormat('dd.MM.yyyy-HH_mm')}.xlsx`, res.data);
        });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectedPatients(reportPatients.map(x => x.id));
        }
        else {
            setSelectedPatients([]);
        }
    };

    const handleSelectSingleClick = (event) => {
        let value = parseInt(event.target.value);

        if (event.target.checked && selectedPatients.indexOf(value) === -1) {
            setSelectedPatients([...selectedPatients, value]);
        } else {
            setSelectedPatients(selectedPatients.filter(x => x !== value));
        }
    }


    useEffect(() => {
        getAllInstitutions();
    }, [getAllInstitutions]);

    return (
        <Grid item>
            <Grid container item justifyContent='center' style={{ paddingBottom: '20px' }} spacing={2}>
                <Grid item>
                    <Typography variant='h5'>Raviskeemide raport</Typography>
                </Grid>
                <Grid item>
                    {
                        reportPatients !== undefined && <Button variant='contained' onClick={() => { setReportPatients(undefined); setSelectedPatients([]); }}>Tagasi</Button>
                    }
                </Grid>
            </Grid>
            {
                reportPatients === undefined &&
                <>
                    <Grid item>
                        <Autocomplete
                            disablePortal
                            required
                            options={institutions}
                            getOptionLabel={(institution) => institution.name}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            value={institutions.filter(x => x.id === selectedInstitution)[0] ?? null}
                            onChange={(event, newValue) => {
                                if (newValue !== null)
                                    setSelectedInstitution(newValue?.id);
                                else
                                    setSelectedInstitution(null);
                            }}
                            renderInput={(params) => <TextField
                                margin='normal' {...params} label="Asutus" />}
                        />
                    </Grid>
                    <Grid item container justifyContent='center'>
                        <Button
                            onClick={() => getTreatmentPatients()}
                            variant='outlined'
                        >
                            Loo raport
                        </Button>
                    </Grid>
                </>
            }
            <Grid item>
                {
                    reportPatients !== undefined &&
                    <>
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 300 }}
                                size='small'
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={selectedPatients.length > 0 && selectedPatients.length < reportPatients.length}
                                                checked={selectedPatients.length > 0 && selectedPatients.length === reportPatients.length}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell align='left' style={{ fontWeight: 700 }}>Patsiendi nimi</TableCell>
                                        <TableCell align='left' style={{ fontWeight: 700 }}>Isikukood</TableCell>
                                        <TableCell align='left' style={{ fontWeight: 700 }}>Institutsioon</TableCell>
                                        <TableCell align='left' style={{ fontWeight: 700 }}>Raviskeemi algus</TableCell>
                                        <TableCell align='left' style={{ fontWeight: 700 }}>Raviskeemi pikkus</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        reportPatients.map((patient, i) => {
                                            let rowStyle = patient.treatments.length === 0 && selectedPatients.indexOf(patient.id) !== -1 ? { backgroundColor: '#ffbbbb' } : undefined;
                                            return (
                                                <TableRow
                                                    key={i}
                                                    className='tableRow'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell style={rowStyle} padding="checkbox">
                                                        <Checkbox
                                                            value={patient.id}
                                                            checked={selectedPatients.indexOf(patient.id) !== -1}
                                                            onChange={handleSelectSingleClick}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={rowStyle} align='left'>{patient.name}</TableCell>
                                                    <TableCell style={rowStyle} align='left'>{patient.identificationCode}</TableCell>
                                                    <TableCell style={rowStyle} align='left'>{patient.institution.name}</TableCell>
                                                    <TableCell style={rowStyle} align='left'>{patient.treatments[0]?.startDate && DateTime.fromISO(patient.treatments[0]?.startDate).toFormat('dd.MM.yyyy')}</TableCell>
                                                    <TableCell style={rowStyle} align='left'>{patient.treatments[0]?.treatmentLength}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div>
                            <Button onClick={handlePrint} variant='contained' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                Prindi nimekiri ({selectedPatients.length})
                            </Button>
                        </div>
                        <div>
                            <Button onClick={handleExcel} variant='contained' style={{ marginBottom: '20px' }}>
                                Loo excel
                            </Button>
                        </div>
                    </>
                }
            </Grid>
        </Grid >
    );
}

export default TreatmentReport;