import React, { useContext, useState } from 'react';
import {
    Grid, Button, Dialog, DialogTitle, DialogActions
} from '@mui/material';
import { UserContext } from './context/UserContext';

import { toast } from 'react-toastify';
import axios from './CustomAxios';

function Interfaces() {
    const [userContext, setUserContext] = useContext(UserContext)

    const [open, setOpen] = useState(false);

    const handleSend = () => {
        setOpen(false);
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'drugs/sync')
            .then(res => {
                console.log(res);
            });
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    Oled kindel, et soovid saata ravimid FILIAsse?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Ei</Button>
                    <Button
                        autoFocus
                        onClick={() => toast.promise(
                            handleSend(),
                            {
                                pending: 'Valmistun saatma',
                                success: 'Saadetud',
                                error: 'Eelmine saatmine pole veel lõppenud'
                            }
                        )}
                    >Jah</Button>
                </DialogActions>
            </Dialog>
            <Grid container xs={12} justifyContent='center'>
                <Grid item>
                    <Button
                        onClick={() => setOpen(true)}
                    >
                        Saada ravimid FILIAsse
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default Interfaces;