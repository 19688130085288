import React from 'react';
import {
    Button, Dialog, DialogTitle, DialogActions
} from '@mui/material';

function PackagingDialog({ isDialogOpen, closeDialog, dialogTitle, handleSendAction, handleDownloadAction }) {
    return (
        <Dialog
            open={isDialogOpen}
            onClose={closeDialog}
        >
            <DialogTitle>
                {dialogTitle}
            </DialogTitle>
            <DialogActions>
                <Button
                    autoFocus
                    variant='outlined'
                    onClick={handleSendAction}
                >Saada fail pakendamisse</Button>
                <Button
                    variant='outlined'
                    onClick={handleDownloadAction}
                >Lae fail arvutisse</Button>
                <Button
                    color='error'
                    variant='outlined'
                    onClick={closeDialog}
                >Katkesta</Button>
            </DialogActions>
        </Dialog>
    );
}

export default PackagingDialog;